import React from 'react';
import { Image, View, ScrollView, Pressable, useWindowDimensions, Linking } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { PrimaryNav, Modal, Heading, Button, Span, Link, AssetItem, SelectMenu, TextInput, Notification, HorizontalRule, AddNewAssetButtons } from '../elements';
import { useSpring, animated } from 'react-spring';
import { getInitials, getLabel, sort_by, getOptionsByLabelGroup, now, truncate, getRouteFilter, updateRouteFilter } from '../helpers';
import RegExHelper from '../regex';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function MyYachtsView ({navigation})
{    
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userID,
        userYachts,
        enabledFeatures
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const [getFilter, setFilter] = React.useState(getRouteFilter('yachts') || getLabel('ALL'));
    const [filterVisible, setFilterVisible] = React.useState(false);

    const createLink = (text, url) => {
        return <Link onPress={() => { Linking.openURL(url); }}>{text}</Link>;
    };

    const processLabel = (label, replacements) => {
        let parts = [label];
        replacements.forEach(({ text, component }, replacementIndex) => {
            parts = parts.flatMap((part, partIndex) =>
                typeof part === 'string'
                    ? part.split(text).flatMap((subPart, index, arr) =>
                        index < arr.length - 1
                            ? [subPart, React.cloneElement(component, { key: `${replacementIndex}-${partIndex}-${index}-component` })]
                            : [subPart]
                        )
                    : [part]
            );
        });
        return parts.map((part, index) =>
            typeof part === 'string' ? <React.Fragment key={`fragment-${index}`}>{part}</React.Fragment> : part
        );
    };

    const WEB_LIMITED_BODY_TEXT = processLabel( getLabel('MOBILE_LIMITED_BODY'), [
        { text: 'Apple App Store', component: createLink('Apple App Store', 'https://apps.apple.com/us/app/yachtwave/id6447794172') },
        { text: 'Google Play Store', component: createLink('Google Play Store', 'https://play.google.com/store/apps/details?id=com.yachtwave.app') },
    ]);

    const [primaryMsg, setPrimaryMsg] = React.useState(WEB_LIMITED_BODY_TEXT);
    const [primaryMsgType, setPrimaryMsgType] = React.useState('info');
    const [getAssets, setAssets] = React.useState([]);

    const [yachtValidation, doYachtValidation] = React.useState(false);
    const [yachtErrorMsg, setYachtErrorMsg] = React.useState('');

    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);

    const [addingNew, setAddingNew] = React.useState(false);
    const [newYachtName, setNewYachtName] = React.useState('');
    const [newYachtStatus, setNewYachtStatus] = React.useState('');
    const [yachtStatusSelectVisible, setYachtStatusSelectVisible] = React.useState(false);
    const [newYachtType, setNewYachtType] = React.useState('');
    const [yachtTypeSelectVisible, setYachtTypeSelectVisible] = React.useState(false);
    const [newYachtHIN, setNewYachtHIN] = React.useState('');
    const [newYachtHINValidated, setNewYachtHINValidated] = React.useState('');

    let typeOptions = getOptionsByLabelGroup('types.vessel.types', true);

    let statusOptions = getOptionsByLabelGroup('types.vessel.status', true)
        .filter(value => String(value.value) !== '500' && String(value.value) !== '999')
        .map(value => {
            value.badge = { uri: require('../svg/status_' + value.label.toLowerCase().replace(/\s/g, '') + '.svg') };
            return value;
        });

    React.useEffect(()=> 
    {
        let assets = [];

        if ( userYachts.length )
        {
            let vessels = [];
            
            switch(getFilter)
            {    
                case getLabel('SHARED_WITH_ME'):
                    updateRouteFilter('yachts', getLabel('SHARED_WITH_ME'));
                    vessels = userYachts.filter(function (el)
                    {
                        return el.owner_id !== userID;
                    });
                    break;

                case getLabel('ALL'):
                default:
                    updateRouteFilter('yachts', getLabel('ALL'));

                    vessels = [...userYachts].sort(sort_by('year', false, Date)).reverse();
                    break;
            }
                
            vessels.forEach((yacht, index) => {

                if ( ! enabledFeatures.sharing && yacht.pending ) { return; }

                let menu = [
                    { name:getLabel('EDIT'), color:'green', onPress: ()=>{ navigation.navigate('editYacht', {yachtID:yacht.id}) } },
                    { name:getLabel('DELETE'), color:'red', onPress: ()=>{ setModalAffectID(yacht.id); setModalActive(true); } }
                ];

                if ( true === enabledFeatures.sharing && ! yacht.is_owned_by_me ) {
                    menu = [
                        { name:getLabel('EDIT'), color:'green', onPress: ()=>{ navigation.navigate('editYacht', {yachtID:yacht.id}) } },
                    ];
                }
                else if ( true === enabledFeatures.sharing && yacht.is_owned_by_me ) {
                    menu = [
                        { name:getLabel('EDIT'), color:'green', onPress: ()=>{ navigation.navigate('editYacht', {yachtID:yacht.id}) } },
                        { name:getLabel('SHARE'), color:'blue', onPress: ()=>{ navigation.navigate('shared', {yachtID:yacht.id}) } },
                        { name:getLabel('DELETE'), color:'red', onPress: ()=>{ setModalAffectID(yacht.id); setModalActive(true); } }
                    ];
                }

                assets.push(
                    <AssetItem
                        key={yacht.id}
                        title={yacht.name}
                        initials={yacht.initials}
                        color={yacht.color}
                        description={yacht.description ? yacht.description : ' '}
                        location={yacht.location || ' '}
                        tag={yacht.tag}
                        tagColor={yacht.tagColor}
                        buttons={yacht.pending ? [
                            { name: 'Accept', primary: true, onPress: () => {
                                setPrimaryMsg(WEB_LIMITED_BODY_TEXT);
                                setPrimaryMsgType('info');

                                fetch(apiUrl, {
                                    method: 'POST',
                                    cache: 'no-cache',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        endpoint: 'vessels/share/accept', v: apiVersion,
                                        client_key: localStorage.getItem('clientKey'),
                                        device_id: localStorage.getItem('deviceID'),
                                        payload: {
                                           vessel_id: yacht.id,
                                           is_accepted: true,
                                        }
                                    }),
                                })
                                .then((response) => {
                                    return response.text().then(function(text) {
                                        const result = JSON.parse(text);
                                        if ( result.error )
                                        {
                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                            // setPrimaryMsgType('warning');
                                            // setPrimaryMsg(result.error);
                                            toast.show(result.error, {type: 'danger', duration:10000});
                                            // setLastUpdate(now()); // forces app to refetch all account data
                                        }
                                        else
                                        {
                                            // setPrimaryMsgType('success');
                                            // setPrimaryMsg(getLabel('SHARE_ACCEPTED_INVITEE'));
                                            setModalActive(false);

                                            toast.show(getLabel('SHARE_ACCEPTED_INVITEE'), {type: 'success', duration:10000});
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }
                                    });
                                })
                                .catch(error => {
                                    console.warn(error);
                                    setPrimaryMsgType('warning');
                                    if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                    else { setPrimaryMsg('An error ocurred. See console log.'); }
                                });
                            }},
                            { name: 'Reject', primary: false, onPress: () => {
                                setPrimaryMsg(WEB_LIMITED_BODY_TEXT);
                                setPrimaryMsgType('info');

                                fetch(apiUrl, {
                                    method: 'POST',
                                    cache: 'no-cache',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        endpoint: 'vessels/share/accept', v: apiVersion,
                                        client_key: localStorage.getItem('clientKey'),
                                        device_id: localStorage.getItem('deviceID'),
                                        payload: {
                                           vessel_id: yacht.id,
                                           is_accepted: false,
                                        }
                                    }),
                                })
                                .then((response) => {
                                    return response.text().then(function(text) {
                                        const result = JSON.parse(text);
                                        if ( result.error )
                                        {
                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                            // setPrimaryMsgType('warning');
                                            // setPrimaryMsg(result.error);
                                            toast.show(result.error, {type: 'danger', duration:10000});
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }
                                        else
                                        {
                                            // setPrimaryMsgType('info');
                                            // setPrimaryMsg(getLabel('SHARE_REJECTED_INVITEE'));
                                            setModalActive(false);
                                            toast.show(getLabel('SHARE_REJECTED_INVITEE'), {type: 'success', duration:10000});
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }
                                    });
                                })
                                .catch(error => {
                                    console.warn(error);
                                    setPrimaryMsgType('warning');
                                    if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                    else { setPrimaryMsg('An error ocurred. See console log.'); }
                                });
                            }},
                        ] : false}
                        menu={ yacht.pending || '500' === String(yacht.status_id) || '999' === String(yacht.status_id) ? [] : menu}
                        onPress={ yacht.pending ? false : () => { navigation.navigate('yacht', {yachtID: yacht.id}) }}
                    />
                )
            });            
        }
        
        setAssets(assets);
        
    },[getFilter, userYachts]);

    const newYachtNameInput = React.useRef(null);
    const newYachtHINInput = React.useRef(null);

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    return (
        <View label="myYachts" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} />
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>
            
            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                
                <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-24 lg:h-33 flex-row md:items-center z-10`}>
                    
                    <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{getLabel('ICON_YACHTS')}</Heading>

                    <Pressable
                        style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                        onPress={()=>{setFilterVisible(true)}}
                    >
                        <Image
                            accessibilityHidden={true}
                            source={{ uri: require('../svg/sort.svg') }}
                            resizeMode="contain"
                            style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                        />
                        
                        <Span style={tw`text-sm md:text-base leading-4`}>{getLabel('VIEW')}: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>
                        
                    </Pressable>
                    
                </View>

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-0`}>
                                <Notification
                                    style={tw`my-4 mt-0`}
                                    styleText={tw`text-sm`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { ! getAssets.length &&
                            <View style={tw`justify-center h-full items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/yacht.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ userYachts.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : getLabel('YACHTS_404_TEXT') }</Heading>

                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 md:right-18 pointer-events-none`}>

                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2`}>{getLabel('CONTENT_ADD')}</Span>

                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }


                        { getAssets.length > 0 &&
                            <View style={tw`md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                <>{getAssets}</>
                            </View>
                        }
                    </ScrollView>

                    <AddNewAssetButtons addNewLabel={getLabel('YACHTS_ADD')} onAddNew={()=>{ setAddingNew(true); }} />
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 backdrop-blur-sm w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setYachtErrorMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setYachtErrorMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel="Cancel"
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ getLabel('YACHTS_ADD') }</Heading>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== yachtErrorMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type="warning"
                                        >{yachtErrorMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>

                                    <View style={tw`w-full items-start flex-row justify-between`}>
                                        <Span
                                            style={[
                                                tw`text-[32px] text-white w-24 h-24 mt-4 leading-[96px] text-center font-bold rounded-full`,
                                                1 === parseInt(newYachtStatus) && tw`bg-green`,
                                                2 === parseInt(newYachtStatus) && tw`bg-orange`,
                                                4 === parseInt(newYachtStatus) && tw`bg-purple`,
                                                5 === parseInt(newYachtStatus) && tw`bg-red`,
                                                ! newYachtStatus && tw`bg-blue`
                                                ]}
                                        >{getInitials(newYachtName)}</Span>

                                        <View style={tw`mt-2 md:mt-0 w-68/100 md:w-82/100`}>
                                            <TextInput
                                                autoCapitalize="on"
                                                placeholder={ getLabel('NAME') + '*' }
                                                pattern="^.{2,}$"
                                                validatedValue={(value) => {setNewYachtName(value)}}
                                                passedRef={newYachtNameInput}
                                                required
                                            />

                                            <View style={tw`md:flex md:flex-row md:justify-between`}>

                                                <Pressable
                                                    style={tw`mt-2 md:mt-4 md:w-full`}
                                                    onPress={()=>{setYachtStatusSelectVisible(true)}}
                                                >
                                                    <TextInput
                                                        style={tw`pointer-events-none`}
                                                        image={ newYachtStatus ? { uri: require('../svg/status.svg') } : { uri: require('../svg/status_gray.svg') }}
                                                        image2={newYachtStatus ? statusOptions.find(obj => {return obj.value === newYachtStatus}).badge : { uri: require('../svg/status_null.svg') }}
                                                        styleImage2={tw`ml-0 mr-3`}
                                                        placeholder={ getLabel('STATUS') + '*' }
                                                        value={newYachtStatus ? statusOptions.find(obj => {return obj.value === newYachtStatus}).label : ''}
                                                        validateGroup={yachtValidation}
                                                        required
                                                    />

                                                    <Image
                                                        accessibilityHidden={true}
                                                        source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                        resizeMode="contain"
                                                        style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                                    />
                                                </Pressable>

                                            </View>

                                        </View>
                                    </View>

                                    <View style={tw`w-full items-start flex-row justify-between`}>
                                        <Span></Span>

                                        <View style={tw`mt-2 md:mt-0 w-full md:w-82/100`}>

                                            <Pressable
                                                style={tw`mt-2 md:mt-4 md:w-full`}
                                                onPress={()=>{setYachtTypeSelectVisible(true)}}
                                            >
                                                <TextInput
                                                    style={tw`pointer-events-none`}
                                                    placeholder={ getLabel('TYPE') + '*' }
                                                    image={ newYachtType ? { uri: require('../svg/yacht_blue.svg') } : { uri: require('../svg/yacht_gray.svg') }}
                                                    value={newYachtType ? typeOptions.find(obj => {return obj.value === newYachtType}).label : ''}
                                                    validateGroup={yachtValidation}
                                                    required
                                                />

                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                                />
                                            </Pressable>

                                            <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + getLabel('REQUIRED') }</Span>

                                            <TextInput
                                                style={tw`mt-2 md:mt-4 md:w-full`}
                                                autoCapitalize="off"
                                                placeholder={ getLabel('HIN') + '**' }
                                                image={ newYachtHIN ? { uri: require('../svg/hin.svg') } : { uri: require('../svg/hin_gray.svg') }}
                                                pattern={RegExHelper.validators.hin}
                                                onChangeText={(value) => {setNewYachtHIN(value)}}
                                                validateGroup={yachtValidation}
                                                validatedValue={(value) => {setNewYachtHINValidated(value)}}
                                                validateOnChange={true}
                                                passedRef={newYachtHINInput}
                                            />

                                            { '' === newYachtHIN && '' === newYachtHINValidated &&
                                                <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ getLabel('VESSEL_ADD_INSTRUCT') }</Span>
                                            }

                                            { newYachtHIN === newYachtHINValidated && '' !== newYachtHINValidated &&
                                                <Span style={tw`text-sm text-ink my-2 w-full`}>{ getLabel('HIN_VALID') }</Span>
                                            }

                                            { newYachtHIN !== newYachtHINValidated &&
                                                <Span style={tw`text-sm text-ink my-2 w-full`}>{ getLabel('HIN_INVALID') }</Span>
                                            }



                                        </View>

                                    </View>

                                </View>
                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title="Add"
                                disabled={newYachtName && newYachtStatus && newYachtType ? false : true}
                                validateGroup={yachtValidation}
                                onPress={()=>{
                                    console.log('Added new yacht:');
                                    console.log({
                                        name: newYachtName,
                                        status: newYachtStatus,
                                        type: newYachtType,
                                        hin: newYachtHIN
                                    });

                                    if ( yachtValidation ) { return; }

                                    doYachtValidation(true);
                                    setYachtErrorMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        // only required fields should be:
                                        // name (thus initials)
                                        // status
                                        // type
                                        // hin

                                        if ( ! newYachtName
                                            || ! newYachtStatus
                                            || ! newYachtType
                                            // || ( newYachtHIN && ! newYachtHINValidated )
                                        )
                                        {
                                            let errorMsg = [];

                                            if ( ! newYachtName )
                                                { errorMsg.push(getLabel('YACHT_NAME_REQUIRED')); }
                                            if ( ! newYachtStatus )
                                                { errorMsg.push(getLabel('REQUEST') +' '+ getLabel('STATUS')); }
                                            if ( ! newYachtType )
                                                { errorMsg.push(getLabel('REQUEST') +' '+ getLabel('TYPE')); }
                                            /*if ( newYachtHIN && ! newYachtHINValidated )
                                                { errorMsg.push(getLabel('REQUEST_VALID') +' '+getLabel('HIN')); }*/

                                            setYachtErrorMsg(errorMsg.join('\n'));
                                            doYachtValidation(false);
                                        }
                                        else
                                        {
                                            let today = now();

                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 'vessels/add', v: apiVersion,
                                                    client_key: localStorage.getItem('clientKey'),
                                                    device_id: localStorage.getItem('deviceID'),
                                                    payload: {
                                                       name: newYachtName,
                                                       initials: getInitials(newYachtName),
                                                       status_id: newYachtStatus,
                                                       type_id: newYachtType,
                                                       hin: newYachtHIN,
                                                       date_acquired: today.split('T')[0],
                                                       modified_date: today.replace('T', ' ').replace('Z',''),
                                                    }
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        setYachtErrorMsg(result.error);
                                                        doYachtValidation(false);
                                                    }
                                                    else
                                                    {
                                                        toast.show(getLabel('TOAST_ADD').replace('{object}',getLabel('YACHT')), {type: 'success', duration:2000});
                                                        setTimeout(()=>{setLastUpdate(today);},250); // forces app to refetch all account data

                                                        setTimeout(()=>{
                                                            setAddingNew(false);
                                                            doYachtValidation(false);
                                                            // clear form
                                                            setNewYachtName(''); newYachtNameInput.current.value = ''; newYachtNameInput.current.defaultValue = '';
                                                            setNewYachtStatus('');
                                                            setNewYachtType('');
                                                            setNewYachtHIN(''); newYachtHINInput.current.value = ''; newYachtHINInput.current.defaultValue = '';
                                                        }, 1250);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                if ( ! window.navigator.onLine ) { setYachtErrorMsg('Internet connection failed. Please try again.'); }
                                                else { setYachtErrorMsg('An error ocurred. See console log.'); }
                                                doYachtValidation(false);
                                            });
                                        }
                                    }, 250);
                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ getLabel('VIEW') }
                optionSet="radio"
                buttonTitle={ getLabel('SORT') }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={[
                    getLabel('ALL'),
                    getLabel('SHARED_WITH_ME')
                ]}
                onValueChange={(value) => {setFilter(value)}}
            />

            <SelectMenu
                badge={false}
                title={ getLabel('SELECT') +' '+ getLabel('STATUS') }
                buttonTitle={ getLabel('SELECT') }
                active={yachtStatusSelectVisible}
                setActive={setYachtStatusSelectVisible}
                selectedValue={newYachtStatus}
                options={statusOptions}
                onValueChange={(value) => {setNewYachtStatus(value)}}
            />

            <SelectMenu
                badge={false}
                title={ getLabel('SELECT') +' '+ getLabel('TYPE') }
                optionSet="radio"
                buttonTitle={ getLabel('SELECT') }
                active={yachtTypeSelectVisible}
                setActive={setYachtTypeSelectVisible}
                selectedValue={newYachtType}
                options={typeOptions}
                onValueChange={(value) => {setNewYachtType(value)}}
                scrollable={true}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ getLabel('DELETE_CONFIRM_TITLE') }</Heading>
                <Span>{ getLabel('VESSEL_DELETE_BODY') }</Span>
                <Span style={tw`text-red mt-2`}>{ getLabel('PERMANENT_ACTION') }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title="Cancel"
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title="Delete"
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg(WEB_LIMITED_BODY_TEXT);
                            setPrimaryMsgType('info');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'vessels/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsg(result.error);
                                    }
                                    else
                                    {
                                        toast.show(getLabel('TOAST_DELETE').replace('{object}',getLabel('YACHT')), {type: 'success', duration:2000});
                                        setLastUpdate(now()); // forces app to refetch all account data
                                        setModalActive(false);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    );
}

export default MyYachtsView;
