import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, Tag, SpecsCard, AddNewAssetButtons } from '../elements';
import { useSpring, animated } from 'react-spring';
import { getInitials, goPrevScreen, getUnreadNotificationsCount, getPropertiesByGroup, getShares, truncate, now, getLabel, date_MMDDYYYY } from '../helpers';
import RegExHelper from '../regex';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function ShareYachtView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        enabledFeatures
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    React.useEffect(()=>
    {
        if ( ! enabledFeatures.sharing ) { navigation.navigate('yachts'); }
    },[enabledFeatures]);

    const labels = React.useRef(null);
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('CREW_MEMBERS'),
                _ASSET: getLabel('CREW_MEMBER'),
                _404_TEXT: getLabel('SHARE_404_TEXT'),

                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),

                NAME: getLabel('NAME'),
                EMAIL: getLabel('CREATE_HINT_EMAIL'),
                SHARE_ROLE: getLabel('SHARE_ROLE'),
                SHARE_ACCEPTED: getLabel('SHARE_ACCEPTED'),
                SHARE_PENDING: getLabel('SHARE_PENDING'),
                SHARE_INVITED: getLabel('SHARE_INVITED'),
                SHARE_REJECTED: getLabel('SHARE_REJECTED'),
                SHARE_RESEND: getLabel('SHARE_RESEND'),

                SELECT: getLabel('SELECT'),
                SELECTED: getLabel('SELECTED'),
                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                REQUIRED: getLabel('REQUIRED'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),
            };
        }
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);
    
    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);

    const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);
    
    // asset specific input parameters
    const [newShareEmail, setNewShareEmail] = React.useState('');
    const [newShareRole, setNewShareRole] = React.useState('');
    const [shareRoleSelectVisible, setShareRoleSelectVisible] = React.useState(false);

    // input refs for asset specific inputs
    const newShareEmailInput = React.useRef(null);

    // select menu options
    let roleOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'label', false, true );

    React.useEffect(() => {
        let assets = [];

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            let shares = getShares(yachtID);

            shares.forEach((asset, index) => {
                let menu = [];

                if ( yachtObj.is_owned_by_me ) {
                    menu = [
                        { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                            setUpdateID(asset.user_id);
                            setNewShareEmail(asset.email);
                            if ( newShareEmailInput.current?.value ) { newShareEmailInput.current.value = asset.email; }
                            setNewShareRole(asset.security_role);

                            setAddingNew(true);
                            // doNewAssetValidation(true);
                            // setTimeout(()=>{
                            //     doNewAssetValidation(false);
                            //     setAddingNew(true);
                            // },250);
                        }},
                        { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setModalAffectID(asset.user_id); setModalActive(true) } }
                    ]
                }

                assets.push(
                    <AssetItem
                        key={asset.user_id}
                        title={asset.name}
                        description={asset.security_role_name}
                        initials={getInitials(asset.name)}
                        color={'accepted' === asset.status ? 'green' : ( 'rejected' === asset.status ? 'red' : 'blue' ) }
                        email={asset.email}
                        tag={'accepted' === asset.status ? labels.current?.SHARE_ACCEPTED : ( 'rejected' === asset.status ? labels.current?.SHARE_REJECTED : labels.current?.SHARE_INVITED )}
                        tagColor={'accepted' === asset.status ? 'green' : ( 'rejected' === asset.status ? 'red' : 'blue' ) }

                        menu={! asset.is_owner ? menu : []}
                        onPress={() => {
                            setViewingAssetObj(asset);
                            setViewingAsset(true);
                        }}
                    />
                )
            });
        }

        setAssets(assets);
    },[yachtID, userYachts, labels]);
    

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="shareYacht" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
                
                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }
                        
                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>
                        
                    </Pressable>
                </View>
            </View>
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >

                        <View style={tw`hidden md:flex`}>
                                <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                    <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                    {yacht.name}
                                </Heading>

                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { getAssets.length < 1 &&
                            <View style={tw`justify-center grow items-center`}>

                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/shared_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ getShares(yachtID)?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>

                                { true === yacht.is_owned_by_me &&
                                    <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>

                                        <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/arrow_organic.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-9 w-18 left-14 md:left-16`}
                                        />
                                    </View>
                                }
                            </View>
                        }
    
                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex`}>{ labels.current?._ASSETS }</Heading>

                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }
                        
                    </ScrollView>

                    { true === yacht.is_owned_by_me &&
                        <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                            setUpdateID(0);
                            setNewShareEmail(''); if ( newShareEmailInput?.current?.value ) { newShareEmailInput.current.value = '' };
                            setNewShareRole('');

                            // doNewAssetValidation(true);
                            setTimeout(()=>{
                                // doNewAssetValidation(false);
                                setAddingNew(true);
                            }, 250);
                        }} />
                    }
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>
                
                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>
                                    {updateID === 0 ?
                                        <TextInput
                                            style={tw`mt-2 md:w-48.5/100`}
                                            autoCapitalize="off"
                                            placeholder={ labels.current?.EMAIL +'*' }
                                            image={ newShareEmail ? { uri: require('../svg/email_blue.svg') } : { uri: require('../svg/email_gray.svg') }}
                                            keyboardType="email-address"
                                            pattern="^\S+@\S+\.\S+$"
                                            validatedValue={(value) => {setNewShareEmail(value)}}
                                            validateOnChange={true}
                                            passedRef={newShareEmailInput}
                                            required
                                        />
                                    :
                                        <View style={tw`w-full`}>
                                            <SpecsCard style={tw`w-full`} label={ labels.current?.EMAIL } labelImage={{ uri: require('../svg/email_blue.svg') }} value={newShareEmail} />
                                            <HorizontalRule style={tw`mt-4 mb-3 -left-5 w-screen md:w-full md:left-0`} />
                                        </View>

                                    }
                                    
                                    <Pressable
                                        style={[tw`mt-2`, updateID === 0 ? tw`md:w-48.5/100` : tw`md:w-full`]}
                                        onPress={()=>{setShareRoleSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.SHARE_ROLE +'*' }
                                            image={ newShareRole ? { uri: require('../svg/setting_blue.svg') } : { uri: require('../svg/setting_gray.svg') }}
                                            value={newShareRole ? truncate(roleOptions.find(obj => {return obj.value == newShareRole}).label, 26) : ''}
                                            required
                                        />
    
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>
                                </View>
                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />
                                
                            <Button
                                style={tw`m-5`}
                                title={0 !== updateID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={newShareEmail && newShareRole ? false : true}
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Added/Updated user:');
                                    console.log({
                                        vessel_id: yachtID,
                                        email: newShareEmail,
                                        security_role: newShareRole,
                                    });

                                    if ( newAssetValidation ) { return; }
                                    
                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');
                                    setPrimaryMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( ! newShareEmail
                                            || ! newShareRole
                                        )
                                        {
                                            let errorMsg = [];

                                            if ( ! newShareEmail )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.EMAIL); }
                                            if ( ! newShareRole )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.SHARE_ROLE); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            let payload = {
                                                vessel_id: yachtID,
                                                email: newShareEmail,
                                                security_role: newShareRole,
                                            };
                                            if ( 0 !== updateID ) { payload.user_id = updateID; delete payload.email; }

                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 0 !== updateID ? 'vessels/share/update' : 'vessels/share/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        // setNewAssetMsgType('warning');
                                                        // setNewAssetMsg(result.error);
                                                        toast.show(result.error, {type: 'danger', duration:10000});
                                                        doNewAssetValidation(false);
                                                    }
                                                    else
                                                    {
                                                        setAddingNew(false);
                                                        doNewAssetValidation(false);
                                                        if ( result.msg )
                                                        {
                                                            // setPrimaryMsgType('success');
                                                            // setPrimaryMsg(result.msg);
                                                            toast.show(result.msg, {type: 'success', duration:10000});
                                                        }
                                                        else {
                                                            // setPrimaryMsgType('success');
                                                            // setPrimaryMsg(0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD);
                                                            toast.show((0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}', labels.current?._ASSET), {type: 'success', duration:2000});
                                                        }

                                                        setTimeout(()=>{
                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                        }, 500);

                                                        setTimeout(()=>{
                                                            setNewShareEmail(''); if ( newShareEmailInput?.current?.value ) { newShareEmailInput.current.value = '' };
                                                            setNewShareRole('');
                                                        }, 1500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setNewAssetMsgType('warning');
                                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                doNewAssetValidation(false);
                                            });
                                        }
                                    }, 250);

                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2}>{ viewingAssetObj.name }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                { /* 0 !== viewingAssetObj.is_private &&
                                    <Span style={tw`text-gray6 text-sm shrink-0 text-right`}>{ labels.current?.PRIVATE }</Span>
                                */ }

                                <View style={tw`flex-col justify-center items-end`}>
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>
                                        { viewingAssetObj.accept_date ? date_MMDDYYYY( viewingAssetObj.accept_date ) : '' }
                                    </Span>
                                    <Tag color={ 'accepted' === viewingAssetObj.status ? 'green' : ( 'rejected' === viewingAssetObj.status ? 'red' : 'blue' ) }>
                                        { 'accepted' === viewingAssetObj.status ? labels.current?.SHARE_ACCEPTED : ( 'rejected' === viewingAssetObj.status ? labels.current?.SHARE_REJECTED : labels.current?.SHARE_INVITED ) }
                                    </Tag>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View>
                                    <Span style={tw`font-bold`}>{ viewingAssetObj.name }</Span>
                                    <View style={tw`flex-col justify-center items-start`}>
                                        <Span style={tw`text-gray6 text-sm leading-6`}>
                                            { viewingAssetObj.accept_date ? date_MMDDYYYY( viewingAssetObj.accept_date ) : '' }
                                        </Span>
                                        <Tag style={tw`mt-2`} color={ 'accepted' === viewingAssetObj.status ? 'green' : ( 'rejected' === viewingAssetObj.status ? 'red' : 'blue' ) }>
                                            { 'accepted' === viewingAssetObj.status ? labels.current?.SHARE_ACCEPTED : ( 'rejected' === viewingAssetObj.status ? labels.current?.SHARE_REJECTED : labels.current?.SHARE_INVITED ) }
                                        </Tag>
                                    </View>
                                </View>

                                { /* false !== viewingAssetObj.is_private &&
                                    <View>
                                        <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`}>{ labels.current?.PRIVATE }</Span>
                                    </View>
                                */ }
                            </View>
                            <HorizontalRule style={tw`mb-4 md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5`}
                            >
                                <View style={tw`md:flex-col md:flex-wrap md:justify-between md:min-h-[300px]`}>
                                    <View style={tw`w-full`}>
                                        <SpecsCard
                                            style={tw`w-full`}
                                            label={ labels.current?.SHARE_ROLE }
                                            labelImage={{ uri: require('../svg/setting_blue.svg') }}
                                            value={viewingAssetObj.security_role_name}
                                        />
                                        <SpecsCard
                                            style={tw`w-full mt-2`}
                                            label={ labels.current?.EMAIL }
                                            labelImage={{ uri: require('../svg/email_blue.svg') }}
                                            value={viewingAssetObj.email}
                                        />
                                    </View>
                                    { ( 'pending' === viewingAssetObj.status || 'rejected' === viewingAssetObj.status ) &&
                                        <View>
                                            <Button
                                                style={tw`my-5`}
                                                title={labels.current?.SHARE_RESEND}
                                                validateGroup={newAssetValidation}
                                                onPress={()=>{
                                                    console.log('reshared with user:');
                                                    console.log({
                                                        vessel_id: yachtID,
                                                        email: viewingAssetObj.email,
                                                        security_role: viewingAssetObj.security_role,
                                                    });

                                                    if ( newAssetValidation ) { return; }

                                                    doNewAssetValidation(true);
                                                    setNewAssetMsg('');

                                                    setTimeout(()=>
                                                    {
                                                        // check if fields are valid

                                                        if ( ! viewingAssetObj.email
                                                            || ! viewingAssetObj.security_role
                                                        )
                                                        {
                                                            let errorMsg = [];

                                                            if ( ! viewingAssetObj.email )
                                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.EMAIL); }
                                                            if ( ! viewingAssetObj.security_role )
                                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.SHARE_ROLE); }

                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(errorMsg.join('\n'));
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            let payload = {
                                                                vessel_id: yachtID,
                                                                email: viewingAssetObj.email,
                                                                security_role: viewingAssetObj.security_role,
                                                            };

                                                            fetch(apiUrl, {
                                                                method: 'POST',
                                                                cache: 'no-cache',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify({
                                                                    endpoint: 'vessels/share/add', v: apiVersion,
                                                                    device_id: localStorage.getItem('deviceID'),
                                                                    client_key: localStorage.getItem('clientKey'),
                                                                    payload: payload
                                                                }),
                                                            })
                                                            .then((response) => {
                                                                return response.text().then(function(text) {
                                                                    const result = JSON.parse(text);
                                                                    if ( result.error )
                                                                    {
                                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                                        doNewAssetValidation(false);
                                                                        setViewingAsset(false);
                                                                        setPrimaryMsgType('warning');
                                                                        setPrimaryMsg(result.error);
                                                                    }
                                                                    else
                                                                    {
                                                                        setViewingAsset(false);
                                                                        doNewAssetValidation(false);
                                                                        toast.show((labels.current?.TOAST_UPDATE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                                        if ( result.msg )
                                                                        {
                                                                            setPrimaryMsgType('success');
                                                                            setPrimaryMsg(result.msg);
                                                                        }

                                                                        setTimeout(()=>{
                                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                                        }, 250);
                                                                    }
                                                                });
                                                            })
                                                            .catch(error => {
                                                                console.warn(error);
                                                                setViewingAsset(false);
                                                                doNewAssetValidation(false);
                                                                setPrimaryMsgType('warning');
                                                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                                                            });
                                                        }
                                                    }, 250);

                                                }}
                                            />
                                        </View>
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.SHARE_ROLE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={shareRoleSelectVisible}
                setActive={setShareRoleSelectVisible}
                selectedValue={newShareRole}
                options={roleOptions}
                onValueChange={(value) => {setNewShareRole(value)}}
                scrollable={false}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>Are you sure?</Heading>
                <Span>Are you sure you want to delete this share?</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'vessels/share/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       vessel_id: parseInt(yachtID),
                                       user_id: parseInt(modalAffectID),
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsg(result.error);
                                        setPrimaryMsgType('warning');
                                        setModalAffectID(0);
                                        setModalActive(false);
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default ShareYachtView;
